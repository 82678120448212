import React, { useState, useEffect } from "react";
import { TokenInformations, getAuthToken } from "../helpers/auth";
import { redirect } from "react-router-dom";
import Notification from "../components/Notification";
import DeleteLanguageModal from "../components/DeleteLanguageModal";
import EditLanguageModal from "../components/EditLanguageModal";
import config from "../assets/config";

const Languages = () => {
    const apiEndpoint = config.apiEndpoint;
    const token = getAuthToken();
    const [showNotification, setShowNotification] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);

    const [checkChange, setCheckChange] = useState(false);
    useEffect(() => {
        const req = '?req=getLanguages';
        setIsAdmin(functionIsAdmin());

        // Fetch languages data from the database or an API
        const fetchLanguagesData = async () => {
            try {
                // Make an API request to fetch languages data
                const response = await fetch(`${apiEndpoint}${req}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setLanguages(data.list);
            } catch (error) {
                setShowNotification({
                    success: false,
                    message: error.message,
                });
                console.error("Error fetching languages data:", error);
            }
        };

        fetchLanguagesData();
    }, [checkChange, apiEndpoint, token]);

    const handleDeleteLanguage = (language) => {
        setSelectedLanguage(language);
    };

    const handleEditLanguage = (language) => {
        setSelectedLanguage(language);
        setIsEdit(true);
    };

    const handleAddLanguage = () => {
        setIsEdit(false);
    };

    return (
        <div className='box'>
            <div className='d-flex justify-content-end align-items-center mb-3'>
                <div className='d-flex justify-content-end mb-3 col-6'>
                    {isAdmin && (
                        <button
                            className='btn btn-success'
                            type='button'
                            data-bs-toggle='modal'
                            data-bs-target='#editLanguageModal'
                            onClick={handleAddLanguage}>
                            <i className='fa fa-user-plus' aria-hidden='true'></i> Add Language
                        </button>
                    )}
                </div>
            </div>
            <div className="table-responsive">
                <table className='table table-striped users-table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Language Name</th>
                            <th>Language Code</th>
                            {isAdmin && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {languages.map((language) => (
                            <tr key={language.id}>
                                <td>{language.id}</td>
                                <td>{language.language_name}</td>
                                <td>{language.language_shortcode}</td>
                                {isAdmin && (
                                    <td>
                                        <button
                                            className='btn btn-primary edit-tool-btn'
                                            data-bs-toggle='modal'
                                            data-bs-target='#editLanguageModal'
                                            onClick={() => handleEditLanguage(language)} >
                                            <i className='fas fa-user-edit'></i> Edit
                                        </button>
                                        <button
                                            className='btn btn-danger delete-button delete-tool-btn'
                                            data-bs-toggle='modal'
                                            data-bs-target='#deleteLanguageModal'
                                            onClick={() => handleDeleteLanguage(language)} >
                                            <i className='fa-solid fas fa-user-minus'></i> Delete
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isAdmin && (
                <EditLanguageModal
                    selectedLanguage={selectedLanguage}
                    isEdit={isEdit}
                    setCheckChange={setCheckChange}
                    CheckChange={checkChange}
                />
            )}
            {isAdmin && (
                <DeleteLanguageModal
                    selectedLanguage={selectedLanguage}
                    setCheckChange={setCheckChange}
                    CheckChange={checkChange}
                />
            )}
            {showNotification && (
                <Notification
                    isSuccess={showNotification.success}
                    message={showNotification.message}
                    state={setShowNotification}
                />
            )}
        </div>
    );
};

const functionIsAdmin = () => {
    // Get the user_type from TokenInformations
    const userType = TokenInformations().user_type;

    // Check if userType is neither "100" (Admin) nor "50" (Moderator)
    if (!["100", "50"].includes(userType)) {
        redirect("/login");
    }

    // Check if userType is "100" (Admin)
    return userType === "100"; // Return true if userType is "100" (Admin), otherwise return undefined
};



export default Languages;
