import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cidActions } from "../store/cid";
import {
  TokenInformations,
  getAuthToken,
  setCustomClientID,
} from "../helpers/auth";
import { redirect } from "react-router-dom";
import Notification from "../components/Notification";
import EditUserModal from "../components/EditUserModal";
import DeleteUserModal from "../components/DeleteUserModal";

import config from "../assets/config";

const Users = () => {
  const dispatch = useDispatch();
  const apiEndpoint = config.apiEndpoint;
  const token = getAuthToken();
  const [showNotification, setShowNotification] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userInfo, setUserInfo] = useState({
    username: "",
    type: "",
    client_id: "",
    default_lang: "",
    selected_langs: "",
  });
  const [checkChange, setCheckChange] = useState(false);

  useEffect(() => {
    setIsAdmin(checkIfIsAdmin());
    const req = '?req=getUsers';
    // Fetch users data from the database or an API
    const fetchUsersData = async () => {
      try {
        // Make an API request to fetch users data
        const response = await fetch(`${apiEndpoint}${req}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        // Filter out the logged-in user
        const filteredUsers = data.list.filter(user => user.username !== TokenInformations().username);

        setUsers(filteredUsers);
        setLanguages(data.languages);
      } catch (error) {
        setShowNotification({
          success: false,
          message: error.message,
        });
        console.error("Error fetching users data:", error);
      }
    };

    fetchUsersData();
  }, [checkChange, apiEndpoint, token]);

  const handleDeleteUser = (user) => {
    setUserInfo(user);
  };

  const handleEditUser = (user) => {
    setIsEdit(true);
    setUserInfo(user);
  };

  const handleAddUser = () => {
    setIsEdit(false);
    setUserInfo({
      username: "",
      type: "1",
      client_id: "",
      selected_langs: "",
      default_lang: "",
    });
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchQuery(searchValue);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.client_id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getUserType = (type) => {
    if (type === "100") {
      return "Administrator";
    } else if (type === "50") {
      return "Moderator";
    } else {
      return "Member";
    }
  };

  const setCID = (cid) => {
    if (cid.trim().length === 0) {
      setCustomClientID("");
    }
    dispatch(cidActions.setCid(cid));
  };

  const getLanguageName = (languageId) => {
    const language = languages.find(lang => lang.id === languageId);
    return language ? language.language_name : '';
  };

  const getSelectedLanguages = (selectedLangs, default_lang) => {
    if (selectedLangs === '-1') {
      return languages.map(lang => lang.language_name).join(', ');
    }

    const selectedLangIds = selectedLangs.split(',');
    let selectedLanguages = selectedLangIds.map(langId => {
      const language = languages.find(lang => lang.id === langId);
      return language ? language.language_name : '';
    });

    // Check if defaultLanguage exists in selectedLanguages
    const defaultLanguageExists = selectedLangIds.some(
      (lang) => lang === default_lang
    );

    // If defaultLanguage exists, remove it from selectedLanguages
    if (defaultLanguageExists) {
      // Check if defaultLanguage exists in selectedLangIds
      const defaultLanguageIndex = selectedLangIds.indexOf(default_lang);

      // If defaultLanguage exists, remove it from selectedLangIds and selectedLanguages
      if (defaultLanguageIndex !== -1) {
        selectedLangIds.splice(defaultLanguageIndex, 1);
        selectedLanguages.splice(defaultLanguageIndex, 1);
      }
    }
    return selectedLanguages.join(', ');
  };

  return (
    <div className='box'>
      <div className='d-flex justify-content-end align-items-center mb-3 row'>
        <div className='col-sm-12 col-md-6' >
          <input
            type='text'
            className='form-control mb-3 user-search-input'
            placeholder='Search Username or Client ID...'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className='d-flex justify-content-end mb-3 col-sm-12 col-md-6' >
          {isAdmin && (
            <button
              className='btn btn-success'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#editUserModal'
              onClick={handleAddUser}>
              <i className='fa fa-user-plus' aria-hidden='true'></i> Add User
            </button>
          )}
        </div>
      </div>
      <div className="table-responsive">
      <table className='table table-striped users-table'>
        <thead>
          <tr>
            <th>
              {" "}
              <button className='btn btn-link' onClick={() => setCID("")}>
                DeSelect
              </button>
            </th>
            <th>Username</th>
            <th>Type</th>
            <th>Client ID</th>
            <th>Default Language</th>
            <th>Other Selected Languages</th>
            {isAdmin && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>
                <button
                  className='btn btn-link'
                  onClick={() => setCID(user.client_id)}>
                  Select
                </button>
              </td>
              <td>{user.username}</td>
              <td>{getUserType(user.type)}</td>
              <td>{user.client_id}</td>
              <td>{getLanguageName(user.default_lang)}</td>
              <td>{getSelectedLanguages(user.selected_langs, user.default_lang)}</td>
              {isAdmin && (
                <td>
                  <button
                    className='btn btn-primary edit-tool-btn'
                    data-bs-toggle='modal'
                    data-bs-target='#editUserModal'
                    onClick={() => handleEditUser(user)}>
                    <i className='fas fa-user-edit'></i> Edit
                  </button>
                  <button
                    className='btn btn-danger delete-button delete-tool-btn'
                    data-bs-toggle='modal'
                    data-bs-target='#deleteUserModal'
                    onClick={() => handleDeleteUser(user)}>
                    <i className='fa-solid fas fa-user-minus'></i> Delete
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {isAdmin && (
        <EditUserModal
          user={userInfo}
          languages={languages}
          isEdit={isEdit}
          setCheckChange={setCheckChange}
          CheckChange={checkChange}
        />
      )}
      {isAdmin && (
        <DeleteUserModal
          user={userInfo}
          languages={languages}
          setCheckChange={setCheckChange}
          CheckChange={checkChange}
        />
      )}
      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}
    </div>
  );
};

const checkIfIsAdmin = () => {
  // Get the user_type from TokenInformations
  const userType = TokenInformations().user_type;

  // Check if userType is neither "100" (Admin) nor "50" (Moderator)
  if (!["100", "50"].includes(userType)) {
    redirect("/login");
  }

  // Check if userType is "100" (Admin)
  return userType === "100"; // Return true if userType is "100" (Admin), otherwise return undefined
};



export default Users;
