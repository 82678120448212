import React, {useEffect, useState } from "react";
import { getAuthToken } from "../helpers/auth";
import Notification from "./Notification";
import config from "../assets/config";

const EditLanguageModal = ({ selectedLanguage, isEdit, setCheckChange }) => {
  const token = getAuthToken();
  const apiEndpoint = config.apiEndpoint;
  const [showNotification, setShowNotification] = useState(false);
  const [languageName, setLanguageName] = useState('');
  const [languageCode, setLanguageCode] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("id", isEdit && selectedLanguage.id);
    formData.append("language", languageName);
    formData.append("code", languageCode);

    const endpoint = isEdit ? "?req=editLanguage" : "?req=addLanguage";
    const requestUrl = apiEndpoint + endpoint;

    fetch(requestUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (endpoint.includes("add_user")) {
            setLanguageName("");
            setLanguageCode("");
          }
          setCheckChange((prevCheckChange) => !prevCheckChange);
        }
        setShowNotification({
          success: response.success,
          message: response.message,
        });
      })
      .catch((error) => {
        console.error(error);
        setShowNotification({
          success: false,
          message: "Error updating language! - " + error.message,
        });
      });
  };

  const handleLanguageNameChange = (e) => {
    setLanguageName(e.target.value);
  };
  const handleLanguageCodeChange = (e) => {
    setLanguageCode(e.target.value);
  };
  useEffect(() => {
    setLanguageName(isEdit ? selectedLanguage.language_name : '');
    setLanguageCode(isEdit ? selectedLanguage.language_shortcode : '');
  }, [isEdit, selectedLanguage]);
  
  return (
    <>
      <div
        className='modal fade'
        id='editLanguageModal'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                {isEdit ? "Edit Selected Language" : "Add Language"}
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <form onSubmit={handleSubmit} method='POST'>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='language' className='form-label'>
                    Language
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='edit_language_language'
                    value={languageName}
                    onChange={handleLanguageNameChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='languageCode' className='form-label'>
                    Language Code
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='edit_user_languageCode'
                    value={languageCode}
                    onChange={handleLanguageCodeChange}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='submit'
                  data-bs-dismiss='modal'
                  className='btn btn-primary'>
                  <i className='fas fa-save'></i>
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}
    </>
  );
};

export default EditLanguageModal;
