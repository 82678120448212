import { redirect } from "react-router-dom";
import LoginForm from "../components/layouts/LoginForm";
import config from "../assets/config";

function Login() {
  return <LoginForm />;
}

export default Login;

// Function for handling login action
export async function action({ request }) {
  const apiEndpoint = config.apiEndpoint;
  // Extract form data from the request
  const data = await request.formData();
  const req = '?req=login';
  // Send a POST request to the login endpoint with the form data
  const response = await fetch(apiEndpoint + req, {
    method: "POST",
    body: data,
  });

  // If the response status is 422 or 401, return the response as it is
  if (response.status === 422 || response.status === 401) {
    return response;
  }

  // If the response status is not okay, throw an error with a custom message
  if (!response.ok) {
    throw new Error("Could not authenticate user.");
  }

  // Extract the token from the response data
  const resData = await response.json();
  const token = resData.token;

  // Store the token in the local storage
  localStorage.setItem("token", token);
  // Redirect the user to the home page
  return redirect("/");
}
