import { createSlice } from "@reduxjs/toolkit";
import { setCustomClientID } from "../helpers/auth";

// Create a slice for the cid state
const cidSlice = createSlice({
  name: "cid",
  initialState: { cid: "" },
  reducers: {
    // Define the setCid reducer to set the custom client ID
    setCid(state, action) {
      // Call the setCustomClientID helper function to set the custom client ID
      setCustomClientID(action.payload);

      // Update the cid state with the new custom client ID
      state.cid = action.payload;
    },
  },
});

// Export the cidActions object containing all the action creators
export const cidActions = cidSlice.actions;

// Export the reducer function generated by createSlice
export default cidSlice.reducer;
