import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setConfigurations } from "../../store/config";
import { Outlet, redirect } from "react-router-dom";
import ProfileModal from "../../components/ProfileModal";
import Navbar from "./Navbar";
import {
  TokenInformations,
  getAuthToken,
  getCustomClientID,
  isTokenExpired,
} from "../../helpers/auth";

import config from "../../assets/config";

const RootLayout = () => {
  const domain_name = TokenInformations().domain_name;
  const apiUrl = `https://${domain_name}/front/face/cnf/current/${domain_name}/cnf.txt`;  
  // const apiUrl = `http://${domain_name}/sport/front/face/cnf/current/${domain_name}/cnf.txt`;
  
  const dispatch = useDispatch();

  // Use useEffect to fetch configurations once when the component mounts
  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch configurations');
        }

        const data = await response.text();

        // Split the file content by lines
        const lines = data.split('\n');

        // Initialize an empty object to store configurations
        const configObject = {};

        // Iterate over each line and parse key-value pairs
        lines.forEach((line) => {
          const [key, value] = line.split('=');
          if (key && value) {
            configObject[key.trim()] = value.trim();
          }
        });

        // Update state with the parsed configurations
        dispatch(setConfigurations(configObject));
      } catch (error) {
        console.error('Error fetching configurations:', error);
      }
    };

    fetchConfigurations();
  }, [apiUrl, dispatch]);

  return (
    <>
      <ProfileModal />
      <Navbar />
      <div className='container-fluid content'>
        <Outlet />
      </div>
    </>
  );
};

export default RootLayout;

export async function loader({ request }) {
  const req = '?req=getBanners';
  const RIGHT_BOTTOM_BANNER = "RightBottomBanner";
  const LEFT_TOP_BANNER = "LeftTopBanner";
  let requestFrom = "&type=TopCenterBanner";
  const apiEndpoint = config.apiEndpoint;

  // If no token or token is expired, redirect to login page.
  const token = getAuthToken();
  if (!token || isTokenExpired()) {
    return redirect("/login");
  }

  // Get for wich part request is sent.
  let url = request.url;
  const parts = url.split("/");
  const dynamicPart = parts[parts.length - 1];
  if (dynamicPart.toLowerCase() === "right-bottom-banner") {
    requestFrom = "&type=" + RIGHT_BOTTOM_BANNER;
  } else if (dynamicPart.toLowerCase() === "left-top-banner") {
    requestFrom = "&type=" + LEFT_TOP_BANNER;
  }

  try {
    /* 
      Get client ID and send request with apiEndpoint + Request Data for Banners + Client ID
      This part is optional, only fills if we select to change other client, if not it gets from token.
    */
    let cid = getCustomClientID();
    if (cid !== "") {
      cid = "&cid=" + cid;
    }
    const response = await fetch(apiEndpoint + req + requestFrom + cid, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let responseData = await response.json();
    if (Object.keys(responseData.data).length !== 0) {
      responseData.data = [...responseData.data].sort((a, b) => a.order_no - b.order_no);
      responseData.data[0] = {
        ...responseData.data[0],
        active: true,
      };
    }
    return responseData;
  } catch (error) {
    console.error(error);
    return null;
  }
}
