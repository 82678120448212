import { redirect } from "react-router-dom";

export function action() {

  // Remove token and expiration from local storage
  localStorage.removeItem("token");
  localStorage.removeItem("expiration");
  localStorage.removeItem("cid");

  // Redirect to the login page
  return redirect("/login");
}
