// Create a new slice for configurations in /src/store/config.jsx

import { createSlice } from "@reduxjs/toolkit";

// Create a slice for the configurations state
const configSlice = createSlice({
  name: "config",
  initialState: { configurations: {} },
  reducers: {
    // Action to set the configurations state with new configurations
    setConfigurations(state, action) {
      state.configurations = action.payload;
    },
  },
});

// Export the config actions
export const { setConfigurations } = configSlice.actions;

// Export the config reducer
export default configSlice.reducer;
