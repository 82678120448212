import React, { useState } from "react";
import { getAuthToken, getCustomClientID } from "../helpers/auth";
import BackDrop from "./layouts/Backdrop";
import config from "../assets/config";

const DeleteItem = ({ item, setItems, index, setShowNotification }) => {
  const apiEndpoint = config.apiEndpoint;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const sendData = async (index, item) => {
    try {
      const formData = new FormData();
      const token = getAuthToken();
      let cid = getCustomClientID();
      const req = '?req=deleteBanner';

      if (cid !== "") {
        cid = "&cid=" + cid;
      }

      // Convert JSON object to a string
      const jsonItem = JSON.stringify(item);

      // Append item to the FormData object
      formData.append("item", jsonItem);

      // Send the POST request
      const response = await fetch(apiEndpoint + req + cid, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const resData = await response.json();

      if (resData.success) {
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          const deletedItem = updatedItems.splice(index, 1)[0];

          if (deletedItem.active) {
            const nextIndex = index < updatedItems.length ? index : index - 1;
            if (nextIndex >= 0) {
              updatedItems[nextIndex].active = true;
            }
          }
          return updatedItems;
        });
      }

      // Check if the request was successful
      setShowNotification({
        success: resData.success,
        message: resData.message,
      });
    } catch (error) {
      console.error(error);
      setShowNotification({
        success: false,
        message: "Error Deleting Banner! - " + error.message,
      });
    }
  };

  const handleDelete = () => {
    sendData(index, item);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      {showConfirmation && (
        <div className='d-flex justify-content-center'>
          <BackDrop />
          <div id="delete-card-modal" className='card fixed-center zindex-sticky card-modal'>
            <div className='card-body'>
              <p className='card-text'>
                Are you sure you want to delete this item?
              </p>
              <div className='card-text d-flex justify-content-center'>
                <button className='btn btn-success me-1' onClick={handleDelete}>
                  Yes
                </button>
                <button className='btn btn-danger ms-1' onClick={handleCancel}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        className='btn btn-danger btn-sm draggable-delete-button'
        onClick={() => setShowConfirmation(true)}>
        <span>
          <i className="fas fa-trash-alt"></i> Delete Banner
        </span>
      </button>

    </>
  );
};

export default DeleteItem;
