import React, { useEffect, useState } from "react";
import { getAuthToken } from "../helpers/auth";
import Notification from "./Notification";
import Select from 'react-select';
import config from '../assets/config'
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #8c9097',
    backgroundColor: '#212934',
    color: '#ced6e2',
    fontSize: '14px',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#374355', // Set your desired background color here
    borderRadius: '4px', // Optional: Add border radius for a rounded look
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2e3848' : '#374355',
    color: '#ced6e2',
    '&:hover': {
      backgroundColor: '#556270', // Change hover color as needed
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: '10px',
    paddingRight: '-10px'
  })
  // Add more custom styles as needed
};


const EditUserModal = ({ user, languages, isEdit, setCheckChange }) => {
  const token = getAuthToken();
  const apiEndpoint = config.apiEndpoint;
  const [showNotification, setShowNotification] = useState(false);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [clientID, setClientID] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [domainName, setDomainName] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
  const [selectLanguageOptions, setSelectLanguageOptions] = useState("");
  const [userSelectedLanguages, setUserSelectedLanguages] = useState("");
  const [topCenterBannerResolutionValue, setTopCenterBannerResolutionValue] = useState("");
  const [rightBottomBannerResolutionValue, setRightBottomBannerResolutionValue] = useState("");
  const [leftTopBannerResolutionValue, setLeftTopBannerResolutionValue] = useState("");

  let selectedLanguages = [];
  if (user.selected_langs !== null && user.selected_langs !== undefined && user.selected_langs !== '') {
    if (user.selected_langs === "-1") {
      // If user.selected_langs is -1, select all languages
      selectedLanguages = languages.map(lang => ({
        value: lang.id,
        label: lang.language_name
      }));
    } else {
      // Otherwise, parse the list of selected languages
      selectedLanguages = user.selected_langs.split(',').map(langID => {
        const language = languages.find(lang => lang.id === langID);
        return { value: language.id, label: language.language_name };
      });
    }
    // Check if defaultLanguage exists in selectedLanguages
    const defaultLanguageExists = selectedLanguages.some(
      (lang) => lang.value === user.default_lang
    );

    // If defaultLanguage exists, remove it from selectedLanguages
    if (defaultLanguageExists) {
      selectedLanguages = selectedLanguages.filter(
        (lang) => lang.value !== user.default_lang
      );
    }
  }

  const options = languages.map(language => ({
    value: language.id,
    label: language.language_name
  }));

  const handleSelectLanguagesChange = (selected) => {
    setSelectLanguageOptions(selected);

    // Extract the language IDs and update selectedLanguages state
    const selectedLangIds = selected.map(lang => lang.value);
    setUserSelectedLanguages(selectedLangIds.join(','));
  };

  const handleDefaultLanguageChange = (language) => {
    setSelectedDefaultLanguage(language);
    setDefaultLanguage(language.id);
  };

  useEffect(() => {

    setUsername(user.username);
    setRole(user.type);
    setClientID(user.client_id);
    setPassword("");
    setNewPassword("");
    setDomainName(user.domain_name)
    setSelectLanguageOptions(selectedLanguages);
    setSelectedDefaultLanguage(languages.find(language => language.id === user.default_lang));
    setDefaultLanguage(user.default_lang);
    setUserSelectedLanguages(selectedLanguages);
    setTopCenterBannerResolutionValue(user.top_center_banner_resolution);
    setRightBottomBannerResolutionValue(user.right_bottom_banner_resolution);
    setLeftTopBannerResolutionValue(user.top_left_banner_resolution);
  }, [user]);

  const validateAndNotify = (resolutionValue, bannerName) => {
    const resolutionRegex = /^\s*\d+\s*x\s*\d+\s*$/;
    if (!resolutionRegex.test(resolutionValue)) {
      // Show error notification if the resolution format is incorrect
      setShowNotification({
        success: false,
        message: `Invalid resolution format for ${bannerName}. Please use the format like '1920x1080' or '1920 x 1080'.`,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate and notify for each resolution value
    if (!validateAndNotify(topCenterBannerResolutionValue, 'Top Center Banner') || !validateAndNotify(rightBottomBannerResolutionValue, 'Right Bottom Banner') || !validateAndNotify(leftTopBannerResolutionValue, 'Left Top Banner')
    ) {
      return;
    }

    const formData = new FormData();
    formData.append("username", username);
    formData.append("type", role);
    formData.append("client_id", clientID);
    formData.append("password", password);
    formData.append("newPassword", newPassword);
    formData.append("domain_name", domainName);
    formData.append("default_lang", defaultLanguage);
    formData.append(
      "selected_langs",
      isEdit
        ? Array.isArray(userSelectedLanguages)
          ? userSelectedLanguages.map(lang => lang.value).join(',')
          : userSelectedLanguages
        : userSelectedLanguages
    );
    // Validate and append resolution values
    if (topCenterBannerResolutionValue) {
      formData.append("topCenterBannerResolution", topCenterBannerResolutionValue.trim());
    }
    if (rightBottomBannerResolutionValue) {
      formData.append("rightBottomBannerResolution", rightBottomBannerResolutionValue.trim());
    }
    if (leftTopBannerResolutionValue) {
      formData.append("leftTopBannerResolution", leftTopBannerResolutionValue.trim());
    }

    const endpoint = isEdit ? "?req=editUser" : "?req=addUser";
    const requestUrl = apiEndpoint + endpoint;

    fetch(requestUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPassword("");
          setNewPassword("");
          setCheckChange((prevCheckChange) => !prevCheckChange);
          if (endpoint.includes("add_user")) {
            setUsername("");
            setRole(user.type);
            setClientID("");
            setTopCenterBannerResolutionValue("");
            setRightBottomBannerResolutionValue("");
            setLeftTopBannerResolutionValue("");
          }

          const modal = document.getElementById('editUserModal');
          modal.classList.remove('show');
          modal.style.display = 'none';
          const backdrop = document.querySelector('.modal-backdrop');
          backdrop.parentNode.removeChild(backdrop);
        }

        setShowNotification({
          success: response.success,
          message: response.message,
        });
      })
      .catch((error) => {
        console.error(error);
        setShowNotification({
          success: false,
          message: "Error updating profile! - " + error.message,
        });
      });
  };

  return (
    <>
      <div
        className='modal fade'
        id='editUserModal'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                {isEdit ? 'Edit Selected User' : 'Add User'}
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <form onSubmit={handleSubmit} method='POST'>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='username' className='form-label'>
                    Username*
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='edit_user_username'
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={isEdit}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='role' className='form-label'>
                    Role*
                  </label>
                  <select
                    className='form-select'
                    id='edit_user_role'
                    value={role || ''}
                    onChange={(e) => setRole(e.target.value)}>
                    <option value='100'>Administrator</option>
                    <option value='50'>Moderator</option>
                    <option value='0'>Member</option>
                  </select>
                </div>
                <div className='mb-3'>
                  <label htmlFor='clientId' className='form-label'>
                    Client ID*
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='edit_user_clientId'
                    value={clientID || ''}
                    onChange={(e) => setClientID(e.target.value)}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='password' className='form-label'>
                    Password{isEdit ? "" : "*"}
                  </label>
                  <input
                    type='password'
                    autoComplete="new-password"
                    className='form-control'
                    id='edit_user_password'
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value)}
                    {...(isEdit ? {} : { required: true })}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='confirmPassword' className='form-label'>
                    Confirm Password{isEdit ? "" : "*"}
                  </label>
                  <input
                    type='password'
                    autoComplete="new-password"
                    className='form-control'
                    id='edit_user_confirmPassword'
                    value={newPassword || ''}
                    onChange={(e) => setNewPassword(e.target.value)}
                    {...(isEdit ? {} : { required: true })}
                  />
                </div>
                <div>
                  <div className='mb-3'>
                    <label htmlFor='editDomainName' className='form-label'>
                      Domain name*
                    </label>
                    <input
                      type='text'
                      name='domain_name'
                      onChange={(event) => setDomainName(event.target.value)}
                      value={domainName || ''}
                      placeholder="example.com"
                      className='form-control'
                      id='editDomainName'
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='editTopCenterBannerResolution' className='form-label'>
                      Top Center Banner Resolution:
                    </label>
                    <input
                      type='text'
                      name='topCenterBannerResolution'
                      onChange={(event) => setTopCenterBannerResolutionValue(event.target.value)}
                      value={topCenterBannerResolutionValue || ''}
                      placeholder="1920x1080"
                      className='form-control'
                      id='editTopCenterBannerResolution'
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='editRightBottomBannerResolution' className='form-label'>
                      Right Bottom Banner Resolution:
                    </label>
                    <input
                      type='text'
                      name='rightBottomBannerResolution'
                      onChange={(event) => setRightBottomBannerResolutionValue(event.target.value)}
                      value={rightBottomBannerResolutionValue || ''}
                      placeholder="600x600"
                      className='form-control'
                      id='editRightBottomBannerResolution'
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='editLeftTopBannerResolution' className='form-label'>
                      Left Top Banner Resolution:
                    </label>
                    <input
                      type='text'
                      name='leftTopBannerResolution'
                      onChange={(event) => setLeftTopBannerResolutionValue(event.target.value)}
                      value={leftTopBannerResolutionValue || ''}
                      placeholder="300x150"
                      className='form-control'
                      id='editLeftTopBannerResolution'
                    />
                  </div>

                </div>
                <div className='mb-3'>
                  <label htmlFor='edit_user_default_language' className='form-label'>
                    Default Language*
                  </label>
                  <div className="dropdown" id='edit_user_default_language'>
                    <a className="btn btn-secondary dropdown-toggle form-control" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {selectedDefaultLanguage ? selectedDefaultLanguage.language_name : 'Please Select Default Language'}
                    </a>
                    <ul className="dropdown-menu">
                      {languages.map(language => (
                        <li key={language.id}>
                          <a className="dropdown-item" onClick={() => handleDefaultLanguageChange(language)}>
                            {language.language_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='mb-3'>
                  <label htmlFor='Languages' className='form-label'>
                    Languages
                  </label>
                  <Select
                    isMulti
                    options={options}
                    value={selectLanguageOptions}
                    onChange={handleSelectLanguagesChange}
                    styles={customSelectStyles}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'>
                  <i className='fas fa-save'></i>
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}
    </>
  );
};

export default EditUserModal;
