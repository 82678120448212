import { decodeToken, isExpired } from "react-jwt";

// Retrieves the authentication token from local storage and checks if it's expired
export function getAuthToken() {
  let token = localStorage.getItem("token");

  if (token != null && isExpired(token)) {
    token = null;
  }

  return token;
}

// Loader function that returns the authentication token
export function loader() {
  return getAuthToken();
}

// Checks if the authentication token is expired
export function isTokenExpired() {
  let token = localStorage.getItem("token");
  return isExpired(token);
}

// Retrieves the authentication token and decodes it, returning the token information
export function TokenInformations() {
  const token = getAuthToken();
  let dToken = decodeToken(token);
  dToken = dToken === undefined ? "" : dToken;
  return dToken;
}

// Sets a custom client ID in local storage
export function setCustomClientID(client_id) {
  if (client_id === "") {
    localStorage.removeItem("cid");
    return;
  }

  localStorage.setItem("cid", client_id);
}

// Retrieves the custom client ID from local storage
export function getCustomClientID() {
  let cid = localStorage.getItem("cid");

  if (cid) {
    return cid;
  }

  return "";
}
