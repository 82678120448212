import React from "react";
import { getAuthToken } from "../helpers/auth";
import config from "../assets/config";

const UpdateOrder = ({ items, type, language, setShowNotification }) => {
  const apiEndpoint = config.apiEndpoint;

  const sendData = async () => {
    try {
      // Create a new FormData object
      const formData = new FormData();
      const token = getAuthToken();
      const req = '?req=updateOrder';
      // Append each item to the FormData object
      items.forEach((item, index) => {
        // Add order_no property to item for tracking order number
        item.order_no = index;

        // Append each key-value pair of the item to the formData
        Object.entries(item).forEach(([key, value]) => {
          formData.append(`items[${index}][${key}]`, value);
        });
      });

      // Send the POST request
      const response = await fetch(`${apiEndpoint}${req}&type=${type}&lang=${language.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const resData = await response.json();

      // Check if the request was successful
      setShowNotification({
        success: resData.success,
        message: resData.message,
      });
    } catch (error) {
      console.error(error);
      setShowNotification({
        success: false,
        message: `Error updating banners order! - ${error.message}`,
      });
    }
  };

  return (
    <>
      <button
        type='button'
        className='btn btn-success'
        style={{
          marginBottom: "10px",
          marginTop: "10px",
          alignSelf: "center",
        }}
        onClick={sendData}>
        Publish Order
      </button>
    </>
  );
};

export default UpdateOrder;
