import { useRouteError } from 'react-router-dom';
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();

  let title = 'An error occurred!';
  let message = 'Something went wrong!';

  if (error.status === 500) {
    message = JSON.parse(error.data).message;
  }

  if (error.status === 404) {
    title = 'Not found!';
    message = 'Could not find resource or page.';
  }

  if (error.status === 401) {
    title = 'Authentication failed.';
    message = 'You are not authorized to perform this action.';
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{title}</h1>
      <p style={styles.message}>{message}</p>
      <Link to='/' style={styles.link}>
        Return to HomePage
      </Link>
    </div>
  );
};

// CSS styles for the ErrorPage component
const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  message: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  link: {
    fontSize: "16px",
    textDecoration: "none",
    color: "blue",
  },
};

export default ErrorPage;
