import { createSlice } from "@reduxjs/toolkit";

// Create a slice for the config state
const langSlice = createSlice({
  name: "languages",
  initialState: { languages: '' },
  reducers: {
    // Action to set the config state with a new apiEndpoint
    setLanguages(state, action) {
      state.languages = action.payload;
    },
  },
});

// Export the config actions
export const { setLanguages } = langSlice.actions;

// Export the config reducer
export default langSlice.reducer;
