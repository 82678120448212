import React, { useState, useEffect } from "react";

const Notification = ({ isSuccess, message, state }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      state("");
    }, 3000); // Change the delay (in milliseconds) to your desired value

    return () => {
      clearTimeout(timer); // Clean up the timer on component unmount
    };
  }, [state]);

  return (
    <>
      {isVisible && (
        <div
          className={`alert bottom-notification ${isSuccess ? "alert-success" : "alert-danger" }`}
          role='alert'>
          {message}
        </div>
      )}
    </>
  );
};

export default Notification;
