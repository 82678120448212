import React, { useState } from "react";
import { getAuthToken } from "../helpers/auth";
import Notification from "./Notification";
import config from "../assets/config";

const DeleteLanguageModal = ({ selectedLanguage, setCheckChange }) => {
  const token = getAuthToken();
  const [showNotification, setShowNotification] = useState(false);
  const apiEndpoint = config.apiEndpoint;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("id", selectedLanguage.id);

    fetch(apiEndpoint + "?req=deleteLanguage", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setCheckChange((prevCheckChange) => !prevCheckChange);
        }
        setShowNotification({
          success: response.success,
          message: response.message,
        });
      })
      .catch((error) => {
        console.error(error);
        setShowNotification({
          success: false,
          message: "Error deleting language! - " + error.message,
        });
      });
  };

  return (
    <>
      <div
        className='modal fade'
        id='deleteLanguageModal'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Delete Selected Language
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <form onSubmit={handleSubmit} method='POST'>
              <div className='modal-body'>
                <div className='mb-3'>
                  <div className='delete-info-description'>
                    <label className='form-label'>
                      Do you want to delete language:
                    </label>
                    <label className='form-label language'>
                      <span className="fw-bolder">{selectedLanguage.language_name}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  type='submit'
                  data-bs-dismiss='modal'
                  className='btn btn-danger'>
                  <i className='fas fa-trash-alt'></i>
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}
    </>
  );
};

export default DeleteLanguageModal;
