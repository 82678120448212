// Update /src/store/index.jsx

import { configureStore } from "@reduxjs/toolkit";
import cidReducer from "./cid";
import langsReducer from "./langs";
import configReducer from "./config";

// Create the Redux store using configureStore function
const store = configureStore({
  reducer: {
    cid: cidReducer,
    languages: langsReducer,
    config: configReducer, 
  },
});

export default store;
