import React, { useState, useRef } from "react";
import { getAuthToken, getCustomClientID } from "../helpers/auth";
import config from "../assets/config";

const UploadImage = ({ addItem, ImageType, language, setShowNotification, requestedResolution }) => {
  const [preview, setPreview] = useState(null);
  const inputFileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [type, setType] = useState(ImageType);
  const [isUploading, setIsUploading] = useState(false);
  const [warningText, setWarningText] = useState();

  const apiEndpoint = config.apiEndpoint;

  // Handle file selection and preview
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
        // Check the image resolution
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const uploadImageRatio = +width / +height;

          // Extract width and height from requestedResolution using regular expression
          const match = requestedResolution.match(/(\d+) *x *(\d+)/);

          if (match) {
            const requestedWidth = parseInt(match[1], 10);
            const requestedHeight = parseInt(match[2], 10);
            const reqUploadRatio = +requestedWidth / +requestedHeight;
            const aspectRatioTolerance = 0.1;

            // Compare with requested resolution
            if (uploadImageRatio === reqUploadRatio) {
              // Image aspect ratio matches the requested one
              setWarningText('');
            } else if (Math.abs(uploadImageRatio - reqUploadRatio) <= aspectRatioTolerance) {
              // Image resolution does not match the requested one but it is inside the tolerance limit
              setWarningText(<span className="badge text-bg-warning text-wrap ms-2">Image aspectRatio is not exact but it can look good.</span>);
            } else {
              // Image resolution does not match the requested one
              setWarningText(<span className="badge text-bg-danger text-wrap ms-2">Image resolution does not match the requested one.</span>);
            }
          };
        };
      }
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      inputFileRef.current.value = null;
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isUploading) {
      // Don't proceed if an upload is already in progress
      return;
    }
    setIsUploading(true);

    if (preview == null) {
      setShowNotification({
        success: false,
        message: "Please select an image first.",
      });

      return;
    }
    const req = '?req=uploadBanner';
    const token = getAuthToken();
    let cid = getCustomClientID();
    if (cid !== "") {
      cid = "&cid=" + cid;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("lang", language.id);

    fetch(apiEndpoint + req + cid, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          let newImage = {
            key: response.id,
            id: response.id,
            url: response.url,
            type: response.type,
            uploaded_by: response.uploaded_by,
            client_id: response.client_id,
            lang: response.lang,
            date_time: response.date_time,
            order_no: response.order_no,
          };
          if (preview != null) {
            addItem(newImage);
            setPreview(null);
            setFile(null);
            inputFileRef.current.value = null;
          }
        }
        setShowNotification({
          success: response.success,
          message: response.message,
        });
        setIsUploading(false);
      })
      .catch((error) => {
        console.error(error);
        setShowNotification({
          success: false,
          message: "Error uploading banner! - " + error.message,
        });
        setIsUploading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} method='POST' encType='multipart/form-data'>

      <span className="badge rounded-pill text-wrap text-bg-light">Image resoluton should be: {requestedResolution} or AspectRatio should match.</span>
      {warningText}
      <div className='mb-3' style={{ marginTop: "20px", marginBottom: "20px" }}>
        <label htmlFor='image' className='form-label'>
          Choose an image:
        </label>
        <input
          className='form-control'
          type='file'
          id='image'
          name='image'
          ref={inputFileRef}
          accept='image/*'
          onChange={(event) => {
            setFile(event.target.files[0]);
            handleFileChange(event);
          }}
          style={{ marginBottom: "20px" }}
        />
        <input
          type='hidden'
          value={type}
          name='type'
          onChange={(event) => setType(event.target.value)}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            type='submit'
            className='btn btn-primary'
            style={{
              marginBottom: "10px",
              maxWidth: "100px",
              alignSelf: "center",
            }}
            disabled={isUploading}>
            <i className="fas fa-upload"></i>
            Upload
          </button>
          {preview && (
            <img
              src={preview}
              className='img-thumbnail'
              style={{
                maxHeight: "200px",
                maxWidth: "50%",
                alignSelf: "center",
              }}
              alt='Preview'
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default UploadImage;
