import { Form, useActionData, useNavigation } from "react-router-dom";
import { useEffect, useState } from "react";
import Notification from "../Notification";
import { useDispatch } from "react-redux";
import { cidActions } from "../../store/cid";

function LoginForm() {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState();
  const data = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  // Set cid to "".
  dispatch(cidActions.setCid(""));

  useEffect(() => {
    if (data && data.message) {
      setShowNotification({
        success: data.success,
        message: data.message,
      });
    }

    if (data && data.errors) {
      setShowNotification({
        success: false,
        message: (
          <ul>
            {Object.values(data.errors).map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        ),
      });
    }
  }, [data]);

  return (
    <div className='hold-transition login-page'>
      <div className='login-box'>
        <div className='login-logo'>
          <b>Admin Login</b>
        </div>
        <div className='login-box-body'>
          <p className='login-box-msg'>Please sign in to continue</p>
          <Form method='POST'>
            <div className='form-group has-feedback'>
              <input
                type='text'
                className='form-control'
                name='username'
                placeholder='Enter your username'
                autoComplete='off'
                required
                autoFocus
              />
              <span className='glyphicon fas fa-user form-control-feedback'></span>
            </div>
            <div className='form-group has-feedback'>
              <input
                type='password'
                className='form-control'
                name='password'
                placeholder='Enter your password'
                required
              />
              <span className='glyphicon fas fa-lock form-control-feedback'></span>
            </div>
            <div className='row'>
              <div className='col-xs-4'>
                <button
                  type='submit'
                  className='btn btn-primary btn-block btn-flat'
                  name='login'
                  disabled={isSubmitting}>
                  <i className='fas fa-sign-in-alt'></i>
                  {isSubmitting ? "Checking..." : "Sign In"}
                </button>
              </div>
            </div>
            {showNotification && (
              <Notification
                isSuccess={showNotification.success}
                message={showNotification.message}
                state={setShowNotification}
              />
            )}
            {/* {data && data.errors && (
                <>              
                <ul>
                  {Object.values(data.errors).map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ul>
                </>                
              )}
              {data && data.message && <><br/><p>{data.message}</p></>} */}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
