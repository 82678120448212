import React, { useEffect, useState } from "react";
import { getAuthToken, getCustomClientID } from "../helpers/auth";
import BackDrop from "./layouts/Backdrop";
import config from "../assets/config";
import DeleteItem from "./DeleteItem";
import { useSelector } from "react-redux";

const BannerOptions = ({ item, setItems, index, setShowNotification }) => {
    const apiEndpoint = config.apiEndpoint;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedRedirect, setSelectedRedirect] = useState(item.redirect);
    const [showCustomUrlInput, setShowCustomUrlInput] = useState(false);
    const [customUrl, setCustomUrl] = useState("");


    // Use useSelector to access configurations directly from Redux store
    const configurations = useSelector((state) => state.config.configurations);

    // Check if any casinoModel is enabled
    let containsNumber = /\d/.test(configurations.casinoModel);
    const casinoEnabled = (configurations.casinoModel !== undefined && configurations.casinoModel !== null && configurations.casinoModel !== '' && containsNumber) || Object.keys(configurations).length === 0;
    // Check if any iBet is enabled
    containsNumber = /\d/.test(configurations.ibet);
    const iBetEnabled = (configurations.ibet !== undefined && configurations.ibet !== null && configurations.ibet !== '' && containsNumber && parseInt(configurations.ibet) > 0) || Object.keys(configurations).length === 0;
    // Check if any keno_bkmk_new is enabled
    containsNumber = /\d/.test(configurations.keno_bkmk_new);
    const keno_bkmk_newEnabled = (configurations.keno_bkmk_new !== undefined && configurations.keno_bkmk_new !== null && configurations.keno_bkmk_new !== '' && containsNumber && parseInt(configurations.keno_bkmk_new) > 0) || Object.keys(configurations).length === 0;

    // Check if any enable_promotion is enabled
    containsNumber = /\d/.test(configurations.enable_promotion);
    const enable_promotionEnabled = (configurations.enable_promotion !== undefined && configurations.enable_promotion !== null && configurations.enable_promotion !== '' && containsNumber && parseInt(configurations.enable_promotion) > 0) || Object.keys(configurations).length === 0;


    const handleCustomUrlChange = (event) => {
        setCustomUrl(event.target.value);
    };

    useEffect(() => {
        // Check if the initial selectedRedirect is "Custom URL"
        if (selectedRedirect === "Custom URL") {
            // Show the custom URL input and set its initial value to redirectTo
            setShowCustomUrlInput(true);
            setCustomUrl(item.redirectTo || ""); // Set to redirectTo value or empty string if null
        }
    }, [selectedRedirect, item.redirectTo]);

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const handleSave = () => {
        // Update the item with the selected redirect value
        const updatedItem = {
            ...item,
            redirect: selectedRedirect,
        };

        // Update the items array in the state
        setItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = updatedItem;
            return updatedItems;
        });

        sendData();
        // Close the modal or perform any other necessary actions
        setShowConfirmation(false);
    };

    const sendData = async () => {
        try {
            const formData = new FormData();
            const token = getAuthToken();
            let cid = getCustomClientID();
            const req = '?req=updateRedirect';

            if (cid !== "") {
                cid = "&cid=" + cid;
            }

            formData.append("BannerId", item.id);
            formData.append("redirect", selectedRedirect);
            // Check if custom URL is provided and append it to the form data
            if (selectedRedirect === "Custom URL" && customUrl) {
                formData.append("redirectTo", customUrl);
            } else if (selectedRedirect === "Aviator -SG") {
                formData.append("redirectTo", 'cs2_2046');
            } else if (selectedRedirect === "Aviator -TT") {
                formData.append("redirectTo", 'cs4.1_15000');
            } else if (selectedRedirect === "Aviator -G") {
                formData.append("redirectTo", 'cs4.1_g_266');
            } else if (selectedRedirect === "xJetx - TT") {
                formData.append("redirectTo", 'cs4.1_47100');
            } else if (selectedRedirect === "xJetx -G") {
                formData.append("redirectTo", 'cs4.1_g_267');
            } else if (selectedRedirect === "Spin2Win -SG") {
                formData.append("redirectTo", 'cs2_23');
            } else if (selectedRedirect === "Live -SG") {
                formData.append("redirectTo", 'cs2_11');
            } else if (selectedRedirect === "RD-Bingo-Keno") {
                formData.append("redirectTo", 'keno_bkmk_new');
            } else if (selectedRedirect === "iBet") {
                formData.append("redirectTo", 'ibet');
            } else if (selectedRedirect === "Promotions") {
                formData.append("redirectTo", 'index');
            }

            // Send the POST request
            const response = await fetch(apiEndpoint + req + cid, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const resData = await response.json();

            // Check if the request was successful
            setShowNotification({
                success: resData.success,
                message: resData.message,
            });
        } catch (error) {
            console.error(error);
            setShowNotification({
                success: false,
                message: "Error Deleting Banner! - " + error.message,
            });
        }
    };

    const handleItemClick = (value) => {
        setSelectedRedirect(value);
        if (value == 'Custom URL' && !showCustomUrlInput) {
            setShowCustomUrlInput(true);
        } else if (showCustomUrlInput) {
            setShowCustomUrlInput(false);
        }
    };

    return (
        <>
            {showConfirmation ? (
                <div className='d-flex justify-content-center'>
                    <BackDrop />
                    <div id="edit-card-modal" className='card fixed-center zindex-sticky card-modal'>
                        <img src={item.url} className='card-img-top' alt='...' />
                        <div className='card-body'>
                            <div>
                                <DeleteItem
                                    item={item}
                                    setItems={setItems}
                                    index={index}
                                    setShowNotification={setShowNotification}
                                />
                                <p className="mt-2"> Please Select where do you want to redirect banner on click: </p>

                                <div className="dropdown mb-2">
                                    <a className="btn btn-secondary dropdown-toggle form-control" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedRedirect ? selectedRedirect : 'No redirect'}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick("None")}>
                                                None
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick("Custom URL")}>
                                                Custom URL
                                            </a>
                                        </li>
                                        {enable_promotionEnabled && <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick("Promotions")}>
                                                Promotions
                                            </a>
                                        </li>}
                                        <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick("My Bets")}>
                                                My Bets
                                            </a>
                                        </li>
                                        {casinoEnabled &&
                                            (<><li>
                                                <a className="dropdown-item" onClick={() => handleItemClick("Casino")}>
                                                    Casino
                                                </a>
                                            </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("Aviator -SG")}>
                                                        Aviator -SG
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("Aviator -TT")}>
                                                        Aviator -TT
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("Aviator -G")}>
                                                        Aviator -G
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("xJetx - TT")}>
                                                        xJetx - TT
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("xJetx -G")}>
                                                        xJetx -G
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("Spin2Win -SG")}>
                                                        Spin2Win -SG
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => handleItemClick("Live -SG")}>
                                                        Live -SG
                                                    </a>
                                                </li></>)
                                        }
                                        {keno_bkmk_newEnabled &&
                                            <li>
                                                <a className="dropdown-item" onClick={() => handleItemClick("RD-Bingo-Keno")}>
                                                    RD-Bingo-Keno
                                                </a>
                                            </li>
                                        }
                                        {iBetEnabled && <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick("iBet")}>
                                                iBet
                                            </a>
                                        </li>}

                                    </ul>
                                </div>
                                {showCustomUrlInput && (
                                    <div className="mb-2">
                                        <label htmlFor="customUrl" className="form-label">Please Enter Your Custom URL: </label>
                                        <input
                                            className="form-control"
                                            id="customUrl"
                                            type="text"
                                            placeholder="https://www.example.com"
                                            aria-label="https://www.example.com"
                                            value={customUrl}
                                            onChange={handleCustomUrlChange}
                                        />
                                    </div>
                                )}

                            </div>
                            <div className='card-text d-flex justify-content-center'>
                                <button className='btn btn-success me-1' onClick={handleSave}>
                                    Save
                                </button>
                                <button className='btn btn-danger ms-1' onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className='draggable-more-button btn btn-sm btn-dark px-1 py-0'
                    onClick={() => setShowConfirmation(true)}>
                    <span>
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                </button>
            )}
        </>
    );
};

export default BannerOptions;
