import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import RootLayout, { loader as RootLayoutLoader } from "./components/layouts/RootLayout";
import TopCenterBanner from "./pages/TopCenterBanner";
import LeftTopBanner from "./pages/LeftTopBanner";
import RightBottomBanner from "./pages/RightBottomBanner";
import ErrorPage from "./pages/Error";
import Login, { action as authAction } from "./pages/Login";
import { action as logoutAction } from "./pages/Logout";
import { loader as TokenLoader } from "./helpers/auth";
import Users from "./pages/Users";
import Languages from "./pages/Languages";

function App() {

  // Create the router using createBrowserRouter
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      id: "root",
      loader: TokenLoader,
      children: [
        {
          // index: true,
          path: "/",
          element: <TopCenterBanner />,
          loader: (params) => RootLayoutLoader({ ...params }),
        },
        {
          path: "/right-bottom-banner",
          element: <RightBottomBanner />,
          loader: (params) => RootLayoutLoader({ ...params }),
        },
        {
          path: "/left-top-banner",
          element: <LeftTopBanner />,
          loader: (params) => RootLayoutLoader({ ...params }),
        },
        {
          path: "/users",
          element: <Users />,
          loader: (params) => RootLayoutLoader({ ...params }),
        },
        {
          path: "/languages",
          element: <Languages />,
          loader: (params) => RootLayoutLoader({ ...params }),
        },
      ],
    },
    {
      path: "/login",
      errorElement: <ErrorPage />,
      element: <Login />,
      action: (params) => authAction({ ...params }),
    },
    {
      path: "/logout",
      action: logoutAction,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;