import React, { useState, useEffect } from "react";
import { TokenInformations, getAuthToken } from "../helpers/auth";
import Notification from "./Notification";
import config from "../assets/config";

import Select from 'react-select';
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #8c9097',
    backgroundColor: '#212934',
    color: '#ced6e2',
    fontSize: '14px',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#374355', // Set your desired background color here
    borderRadius: '4px', // Optional: Add border radius for a rounded look
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2e3848' : '#374355',
    color: '#ced6e2',
    '&:hover': {
      backgroundColor: '#556270', // Change hover color as needed
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: '10px',
    paddingRight: '-10px'
  })
  // Add more custom styles as needed
};

const ProfileModal = () => {
  // Retrieve token and base URL from Redux store
  const token = getAuthToken();
  const apiEndpoint = config.apiEndpoint;

  // Get token information using TokenInformations() function
  let dToken = TokenInformations();

  // State variables
  const [showNotification, setShowNotification] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [domainName, setDomainName] = useState(dToken.domain_name || "");
  const [languages, setLanguages] = useState([]);
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState();
  const [selectLanguageOptions, setSelectLanguageOptions] = useState();
  const [defaultLanguage, setDefaultLanguage] = useState();
  const [topCenterBannerResolutionValue, setTopCenterBannerResolutionValue] = useState(dToken.top_center_banner_resolution || "");
  const [rightBottomBannerResolutionValue, setRightBottomBannerResolutionValue] = useState(dToken.right_bottom_banner_resolution || "");
  const [leftTopBannerResolutionValue, setLeftTopBannerResolutionValue] = useState(dToken.top_left_banner_resolution || "");
  const [userSelectedLanguages, setUserSelectedLanguages] = useState();

  useEffect(() => {
    // Fetch users data from the database or an API
    const fetchLanguages = async () => {
      try {
        // Make an API request to fetch users data
        const response = await fetch(`${apiEndpoint}?req=getLanguages`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLanguages(data.list);
      } catch (error) {
        setShowNotification({
          success: false,
          message: error.message,
        });
        console.error("Error fetching users data:", error);
      }
    };


    fetchLanguages();

  }, [apiEndpoint, token]);

  let selectedLanguages = [];
  if (dToken && languages && dToken.selected_langs && languages.length > 0) {
    if (dToken.selected_langs === "-1") {
      // If dToken.selected_langs is -1, select all languages
      selectedLanguages = languages.map(lang => ({
        value: lang.id,
        label: lang.language_name
      }));
    } else {
      // Otherwise, parse the list of selected languages
      selectedLanguages = dToken.selected_langs.split(',').map(langID => {
        const language = languages.find(lang => lang.id === langID);
        return { value: language.id, label: language.language_name };
      });
    }

    // Check if defaultLanguage exists in selectedLanguages
    const defaultLanguageExists = selectedLanguages.some(
      (lang) => lang.value === dToken.default_lang
    );

    // If defaultLanguage exists, remove it from selectedLanguages
    if (defaultLanguageExists) {
      selectedLanguages = selectedLanguages.filter(
        (lang) => lang.value !== dToken.default_lang
      );
    }

  }

  const options = languages.map(language => ({
    value: language.id,
    label: language.language_name
  }));

  const handleSelectLanguagesChange = (selected) => {
    setSelectLanguageOptions(selected);

    // Extract the language IDs and update selectedLanguages state
    const selectedLangIds = selected.map(lang => lang.value);
    setUserSelectedLanguages(selectedLangIds.join(','));
  };

  const handleDefaultLanguageChange = (language) => {
    setSelectedDefaultLanguage(language);
    setDefaultLanguage(language.id);
  };

  useEffect(() => {
    setSelectLanguageOptions(selectedLanguages);
    if (dToken && dToken.default_lang) {
      setSelectedDefaultLanguage(languages.find(language => language.id === dToken.default_lang));
      setDefaultLanguage(dToken.default_lang);
    }
    setUserSelectedLanguages(selectedLanguages);
  }, [token, languages]);

  const validateAndNotify = (resolutionValue, bannerName) => {
    const resolutionRegex = /^\s*\d+\s*x\s*\d+\s*$/;
    if (!resolutionRegex.test(resolutionValue)) {
      // Show error notification if the resolution format is incorrect
      setShowNotification({
        success: false,
        message: `Invalid resolution format for ${bannerName}. Please use the format like '1920x1080' or '1920 x 1080'.`,
      });
      return false;
    }
    return true;
  };

  // Form submit handler
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate and notify for each resolution value
    if (!validateAndNotify(topCenterBannerResolutionValue, 'Top Center Banner') || !validateAndNotify(rightBottomBannerResolutionValue, 'Right Bottom Banner') || !validateAndNotify(leftTopBannerResolutionValue, 'Left Top Banner')
    ) {
      return;
    }

    // Create form data to send to the server
    const formData = new FormData();
    formData.append("password", password);
    formData.append("newPassword", newPassword);
    formData.append("domain_name", domainName);
    if (dToken && dToken.user_type == '100') {
      formData.append("default_lang", defaultLanguage);
      formData.append(
        "selected_langs",
        Array.isArray(userSelectedLanguages)
          ? userSelectedLanguages.map(lang => lang.value).join(',')
          : userSelectedLanguages
      );
    }

    // Validate and append resolution values
    if (topCenterBannerResolutionValue) {
      formData.append("topCenterBannerResolution", topCenterBannerResolutionValue.trim());
    }
    if (rightBottomBannerResolutionValue) {
      formData.append("rightBottomBannerResolution", rightBottomBannerResolutionValue.trim());
    }
    if (leftTopBannerResolutionValue) {
      formData.append("leftTopBannerResolution", leftTopBannerResolutionValue.trim());
    }

    // Send a POST request to update the profile
    fetch(apiEndpoint + "?req=updateProfile", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPassword("");
          setNewPassword("");

          // Show success notification
          setShowNotification({
            success: response.success,
            message: response.message,
          });

          // Logout user after showing success notification          
          setTimeout(() => {
            // Remove token and expiration from local storage
            localStorage.removeItem("token");
            localStorage.removeItem("expiration");
            localStorage.removeItem("cid");

            // Reload to redirect to the login page
            window.location.reload();
          }, 1000); // Adjust the delay time as needed

        } else {
          // Show error notification if the request fails
          setShowNotification({
            success: false,
            message: "Error updating profile! - " + response.message,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        // Show error notification if the request fails
        setShowNotification({
          success: false,
          message: "Error updating profile! - " + error.message,
        });
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='profileModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Update Profile
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <form onSubmit={handleSubmit} method='POST'>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='username' className='form-label'>
                    Username
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='username'
                    value={dToken && dToken.username}
                    disabled
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='newPassword' className='form-label'>
                    New Password
                  </label>
                  <input
                    type='password'
                    autoComplete="new-password"
                    name='newPassword'
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    className='form-control'
                    id='newPassword'
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='repeatNewPassword' className='form-label'>
                    Repeat New Password
                  </label>
                  <input
                    type='password'
                    autoComplete="new-password"
                    name='repeatPassword'
                    onChange={(event) => setNewPassword(event.target.value)}
                    value={newPassword}
                    className='form-control'
                    id='repeatNewPassword'
                  />
                </div>
                {dToken && dToken.user_type == '100' && (
                <div className='mb-3'>
                    <label htmlFor='editDomainName' className='form-label'>
                      Domain name*
                    </label>
                    <input
                      type='text'
                      name='domain_name'
                      onChange={(event) => setDomainName(event.target.value)}
                      value={domainName || ''}
                      placeholder="example.com"
                      className='form-control'
                      id='editDomainName'
                    />
                  </div>
                )}
                {dToken && dToken.user_type == '100' && (
                  <div>
                    <div className='mb-3'>
                      <label htmlFor='topCenterBannerResolution' className='form-label'>
                        Top Center Banner Resolution:
                      </label>
                      <input
                        type='text'
                        name='topCenterBannerResolution'
                        onChange={(event) => setTopCenterBannerResolutionValue(event.target.value)}
                        value={topCenterBannerResolutionValue}
                        placeholder="1920x1080"
                        className='form-control'
                        id='topCenterBannerResolution'
                      />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='rightBottomBannerResolution' className='form-label'>
                        Right Bottom Banner Resolution:
                      </label>
                      <input
                        type='text'
                        name='rightBottomBannerResolution'
                        onChange={(event) => setRightBottomBannerResolutionValue(event.target.value)}
                        value={rightBottomBannerResolutionValue}
                        placeholder="600x600"
                        className='form-control'
                        id='rightBottomBannerResolution'
                      />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='leftTopBannerResolution' className='form-label'>
                        Left Top Banner Resolution:
                      </label>
                      <input
                        type='text'
                        name='leftTopBannerResolution'
                        onChange={(event) => setLeftTopBannerResolutionValue(event.target.value)}
                        value={leftTopBannerResolutionValue}
                        placeholder="300x150"
                        className='form-control'
                        id='leftTopBannerResolution'
                      />
                    </div>

                  </div>
                )}
                {languages.length > 0 && dToken && dToken.user_type == '100' && (
                  <div className='mb-3'>
                    <label htmlFor='edit_user_default_language' className='form-label'>
                      Default Language
                    </label>
                    <div className="dropdown" id='edit_user_default_language'>
                      <a className="btn btn-secondary dropdown-toggle form-control" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedDefaultLanguage ? selectedDefaultLanguage.language_name : 'Please Select Default Language'}
                      </a>
                      <ul className="dropdown-menu">
                        {languages.map(language => (
                          <li key={language.id}>
                            <a className="dropdown-item" onClick={() => handleDefaultLanguageChange(language)}>
                              {language.language_name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {languages.length > 0 && dToken && dToken.user_type == '100' && (
                  <div className='mb-3'>
                    <label htmlFor='Languages' className='form-label'>
                      All Selected Languages
                    </label>
                    <Select
                      isMulti
                      options={options}
                      value={selectLanguageOptions}
                      onChange={handleSelectLanguagesChange}
                      styles={customSelectStyles}
                    />
                  </div>
                )}
                <div className="mb-3"><label htmlFor="Languages" className="form-label text-warning">After submitting your changes, you'll be automatically signed out in order for the updates to take effect.</label></div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='submit' className='btn btn-primary'>
                  <i className='fas fa-save'></i>
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}
    </>
  );
};

export default ProfileModal;
