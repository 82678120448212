import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../helpers/StrictModeDroppable";
import UploadImage from "../components/UploadImage";
import UpdateOrder from "../components/UpdateOrder";
import { useLoaderData } from "react-router-dom";
import DeleteItem from "../components/DeleteItem";
import Notification from "../components/Notification";

import { getAuthToken, getCustomClientID, TokenInformations } from "../helpers/auth";
import config from "../assets/config";
import BannerOptions from "../components/BannerOptions";

const TOP_CENTER_BANNER = "TopCenterBanner";
const grid = 8;

const getListStyle = (isDraggingOver, itemsLength) => ({
  background: isDraggingOver ? "lightblue" : "",
  display: "flex",
  padding: grid,
  width: itemsLength * 290 + grid * 4,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
});

const TopCenterBanner = () => {
  const apiEndpoint = config.apiEndpoint;

  const token = getAuthToken();
  let dToken = TokenInformations();
  const default_language_id = dToken.default_lang;
  const loaderData = useLoaderData();
  const default_language = (loaderData.user_languages ?? []).find(language => language?.id === default_language_id);
  const [items, setItems] = useState(loaderData.data);
  const [noItems, setNoItems] = useState(true);
  const [showNotification, setShowNotification] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(default_language);

  const fetchBanners = async (lang) => {
    try {
      const req = '?req=getBanners';
      const RIGHT_BOTTOM_BANNER = "RightBottomBanner";
      const LEFT_TOP_BANNER = "LeftTopBanner";
      let requestFrom = "&type=TopCenterBanner";

      // Get for wich part request is sent.
      let url = window.location.href;
      const parts = url.split("/");
      const dynamicPart = parts[parts.length - 1];
      if (dynamicPart.toLowerCase() === "right-bottom-banner") {
        requestFrom = "&type=" + RIGHT_BOTTOM_BANNER;
      } else if (dynamicPart.toLowerCase() === "left-top-banner") {
        requestFrom = "&type=" + LEFT_TOP_BANNER;
      }

      try {
        /* 
          Get client ID and send request with apiEndpoint + Request Data for Banners + Client ID
          This part is optional, only fills if we select to change other client, if not it gets from token.
        */
        let cid = getCustomClientID();
        if (cid !== "") {
          cid = "&cid=" + cid;
        }

        if (lang && lang !== "") {
          lang = '&lang=' + lang.id;
        }

        const response = await fetch(apiEndpoint + req + requestFrom + cid + lang, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let responseData = await response.json();
        if (Object.keys(responseData.data).length !== 0) {
          responseData.data = [...responseData.data].sort((a, b) => a.order_no - b.order_no);
          responseData.data[0] = {
            ...responseData.data[0],
            active: true,
          };
        }
        setItems(responseData.data);
      } catch (error) {
        console.error(error);
        return null;
      }
    } catch (error) {
      setShowNotification({
        success: false,
        message: error.message,
      });
      console.error("Error fetching users data:", error);
    }
  };

  const handleDefaultLanguageChange = (language) => {
    setSelectedLanguage(language)
    fetchBanners(language);
  };

  // Add item to the list 
  const addItem = (item) => {
    if (item) {
      const updatedItems = [...items, item];
      // Check if there is only one item in the updated array
      if (updatedItems.length === 1) {
        updatedItems[0].active = true; // Add the 'active' property to the item
      }
      setItems(updatedItems);
    }
  };

  useEffect(() => {
    if (items !== undefined && items !== null) {
      setNoItems(items.length === 0);
    }
  }, [items]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };

  const toggleBannerVisibility = async (itemId, currentVisibility) => {
    // Update the visibility locally
    const updatedItems = items.map((item) =>
      item.id === itemId ? { ...item, enabled: currentVisibility ? 0 : 1 } : item
    );
    setItems(updatedItems);

    /* 
      Get client ID and send request with apiEndpoint + Request Data for Banners + Client ID
      This part is optional, only fills if we select to change other client, if not it gets from token.
    */
    let cid = getCustomClientID();
    if (cid !== "") {
      cid = "&cid=" + cid;
    }

    // Send the update to the server
    try {
      const req = '?req=updateBannerVisibility';
      const response = await fetch(apiEndpoint + req + cid, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: itemId, enabled: !currentVisibility ? 1 : 0, client_id: cid }),
      });

      const responseData = await response.json();
      setShowNotification({
        success: responseData.success,
        message: responseData.message,
      });
      if (!responseData.success) {
        // Handle the server-side error
        console.error("Failed to update banner visibility on the server.");
        // Rollback the local update if necessary
        setItems(items);
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error updating banner visibility:", error);
      // Rollback the local update if necessary
      setItems(items);
    }
  };
  
  return (
    <div>
      {/* Carousel */}
      {!noItems && (
        <div className='box'>
          <div
            id='TopCenterBannerCarousel'
            className='carousel slide'
            data-bs-ride='true'
            style={{ width: "100%", height: "auto", aspectRatio: "124/25" }}>
            <div className='carousel-indicators'>
              {items && items.map((item, index) => (
                <button
                  key={item.id}
                  type='button'
                  data-bs-target='#TopCenterBannerCarousel'
                  data-bs-slide-to={index}
                  className={item.active ? "active" : ""}
                  aria-current='true'></button>
              ))}
            </div>
            <div className='carousel-inner'>
              {items && items.map((item) => (
                <div
                  key={item.id}
                  className={`carousel-item ${item.active ? "active" : ""}`}>
                  <img
                    src={item.url}
                    className='d-block'
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "124/25",
                    }}
                    alt="..."
                  />
                </div>
              ))}
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#TopCenterBannerCarousel'
              data-bs-slide='prev'>
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#TopCenterBannerCarousel'
              data-bs-slide='next'>
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      )}

      {/* Language Dropdown */}
      {selectedLanguage && (
        <div id="banners-language" className="mt-3">
          <label htmlFor='edit_user_default_language'>
            Banners Language:
          </label>
          <div className="dropdown ms-3" id='edit_user_default_language'>
            {loaderData.user_languages.length > 1 ? (
              <a className="btn btn-secondary dropdown-toggle form-control" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedLanguage ? selectedLanguage.language_name : 'Please Select Default Language'}
              </a>
            ) : (
              <a className="btn btn-secondary form-control">
                {selectedLanguage ? selectedLanguage.language_name : 'Please Select Default Language'}
              </a>
            )}
            <ul className="dropdown-menu">
              {loaderData.user_languages.map(language => {
                if (selectedLanguage && language.id === selectedLanguage.id) {
                  return null; // Skip rendering the selected default language
                }
                return (
                  <li key={language.id}>
                    <a className="dropdown-item" onClick={() => handleDefaultLanguageChange(language)}>
                      {language.language_name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {/* Drag&Drop Part */}
      {!noItems && (
        <div className='box'>
          <div
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              padding: grid,
            }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='image-list' direction='horizontal'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(
                      snapshot.isDraggingOver,
                      items.length
                    )}
                    {...provided.droppableProps}>
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}>
                            <div className='draggable-container'>
                              <img
                                src={item.url}
                                style={{ maxWidth: "250px" }}
                                alt=''
                              />
                              <button
                                className={`draggable-enable-button btn btn-sm ${item.enabled == 1 ? "btn-success" : "btn-danger"} px-1 py-0`}
                                id='toggle-banner-visibility'
                                onClick={() => toggleBannerVisibility(item.id, item.enabled == 1)}>
                                <span>
                                  {item.enabled == 1 ? (<i className="fas fa-eye"></i>) : (<i className="fas fa-eye-slash"></i>)}
                                </span>
                              </button>

                              <BannerOptions
                                item={item}
                                setItems={setItems}
                                index={index}
                                setShowNotification={setShowNotification}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ textAlign: "center" }}>
            <UpdateOrder
              items={items}
              type={TOP_CENTER_BANNER}
              language={selectedLanguage}
              setShowNotification={setShowNotification}
            />
          </div>
        </div>
      )}

      {/* Upload new Image */}
      <div className='box'>
        <UploadImage
          addItem={addItem}
          ImageType={TOP_CENTER_BANNER}
          language={selectedLanguage}
          setShowNotification={setShowNotification}
          requestedResolution={dToken.top_center_banner_resolution}
        />
      </div>

      { /* Notification Message */}
      {showNotification && (
        <Notification
          isSuccess={showNotification.success}
          message={showNotification.message}
          state={setShowNotification}
        />
      )}

    </div>
  );
};

export default TopCenterBanner;
