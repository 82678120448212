import React, {useEffect} from "react";
import { Form, Link, NavLink, useNavigate  } from "react-router-dom";
import { useSelector } from "react-redux";
import { TokenInformations, getCustomClientID } from "../../helpers/auth";

const Navbar = () => {
  // Get token information
  const tokenInformation = TokenInformations();
  const user_type = tokenInformation.user_type;
  const user_name = tokenInformation.username;

  // Use useSelector to access configurations directly from Redux store
  const configurations = useSelector((state) => state.config.configurations);

  // Access configurations directly from Redux store
  const bannerIframeEnabled = (configurations.banner_iframe !== "0" && configurations.banner_iframe !== "") || Object.keys(configurations).length === 0;
  const rightBottomIframeEnabled = (configurations.sport_right_bottom_iframe !== "0" && configurations.sport_right_bottom_iframe !== "") || Object.keys(configurations).length === 0;
  const leftTopIframeEnabled = (configurations.sport_left_top_iframe !== "0" && configurations.sport_left_top_iframe !== "") || Object.keys(configurations).length === 0;

  // Get the custom client ID from the Redux store
  let cid = useSelector((state) => state.cid.cid);
  if (cid.trim().length === 0) {
    cid = getCustomClientID();
  }

  // Access the navigate  object to perform programmatic navigation
  const navigate  = useNavigate ();
  // Automatically navigate to the first enabled link
  useEffect(() => {
    if (!bannerIframeEnabled) {
      navigate('/right-bottom-banner');
    } else if (!bannerIframeEnabled && !rightBottomIframeEnabled) {
      navigate('/left-top-banner');
    } else if (!bannerIframeEnabled && !rightBottomIframeEnabled && !leftTopIframeEnabled) {
      navigate('/users');
    }
  }, [bannerIframeEnabled, rightBottomIframeEnabled, leftTopIframeEnabled]);

  return (
    <nav className='navbar sticky-top navbar-dark bg-dark navbar-expand-lg'>
      <div className='container-fluid'>
        {/* Logo */}
        <Link to='/' className='navbar-brand' id='logo'>
          <img
            src='/favicon-32x32.png'
            alt='Logo'
            width='30'
            height='32'
            className='d-inline-block align-text-top'
          />
          Admin Panel
        </Link>{" "}
        {/* Selected Client */}
        {cid && `Selected Client: ${cid}`}
        {/* Navbar Toggler */}
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        {/* Navbar Items */}
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
            {/* Top Center Banner */}
            {bannerIframeEnabled && (
              <li className='nav-item'>
                <NavLink
                  to='/'
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current='page'>
                  Top Center Banner
                </NavLink>
              </li>
            )}

            {/* Right Bottom Banner */}
            {rightBottomIframeEnabled && (
              <li className='nav-item'>
                <NavLink
                  to='/right-bottom-banner'
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current='page'>
                  Right Bottom Banner
                </NavLink>
              </li>
            )}

            {/* Left Top Banner */}
            {leftTopIframeEnabled && (
              <li className='nav-item'>
                <NavLink
                  to='/left-top-banner'
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current='page'>
                  Left Top Banner
                </NavLink>
              </li>
            )}

            {/* Users (only visible for user_type 100 - Admin & user_type 50 - Moderator) */}
            {(user_type === "100" || user_type === "50") && (
              <li className='nav-item'>
                <NavLink
                  to='/users'
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current='page'>
                  Users
                </NavLink>
              </li>
            )}

            {/* Languages (only visible for user_type 100 - Admin & user_type 50 - Moderator) */}
            {(user_type === "100" || user_type === "50") && (
              <li className='nav-item'>
                <NavLink
                  to='/languages'
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current='page'>
                  Languages
                </NavLink>
              </li>
            )}
          </ul>

          {/* My Account Dropdown */}
          <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='#'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                My Account
              </Link>
              <ul className='dropdown-menu'>
                {/* UserName */}
                <Link
                  className='dropdown-item d-flex justify-content-center'
                  data-bs-toggle='modal'
                  data-bs-target='#profileModal'
                  to='#'>
                  <span><i className="fas fa-user-circle"></i></span>
                  {user_name}
                </Link>
                {/* Divider */}
                <li>
                  <hr className='dropdown-divider' />
                </li>
                {/* My Profile */}
                <li>
                  <Link
                    className='dropdown-item d-flex justify-content-center'
                    data-bs-toggle='modal'
                    data-bs-target='#profileModal'
                    to='#'>
                    My Profile
                  </Link>
                </li>

                {/* Divider */}
                <li>
                  <hr className='dropdown-divider' />
                </li>

                {/* Logout */}
                <li>
                  <Form action='/logout' method='POST' className="d-grid col-9 mx-auto">
                    <button className='btn btn-danger btn-sm'>Logout</button>
                  </Form>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
